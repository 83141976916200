import { render, staticRenderFns } from "./PlaceEdit.vue?vue&type=template&id=12385f96&scoped=true"
import script from "./PlaceEdit.vue?vue&type=script&lang=js"
export * from "./PlaceEdit.vue?vue&type=script&lang=js"
import style0 from "./PlaceEdit.vue?vue&type=style&index=0&id=12385f96&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12385f96",
  null
  
)

export default component.exports